import React, { useEffect, useState } from "react"
import styles from "./header.module.css"
import logo from "../../img/logo.png"
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai"

function Header(props) {
	const [nav, setNav] = useState(false)

	useEffect(() => {
		document.title = "Аура стирка"
	})
	return (
		<header className={styles.container}>
			<div className={styles.logoImage}>
				<img src={logo} alt='logo' />
			</div>
			<ul
				className={nav ? [styles.menu, styles.active].join(" ") : [styles.menu]}
			>
				<li>
					<a href='#home' onClick={() => setNav(!nav)}>
						ГЛАВНАЯ
					</a>
				</li>
				<li>
					<a href='#price' onClick={() => setNav(!nav)}>
						ЦЕНЫ И КОНТАКТЫ
					</a>
				</li>
				<li>
					<a href='#howAreWorking' onClick={() => setNav(!nav)}>
						КАК МЫ РАБОТАЕМ?
					</a>
				</li>
			</ul>
			<div
				onClick={() => {
					setNav(!nav)
				}}
				className={styles.mobileBtn}
			>
				{nav ? <AiOutlineClose size={25} /> : <AiOutlineMenu size={25} />}
			</div>
		</header>
	)
}

export default Header
