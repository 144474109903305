import React from "react"
import styles from "./main.module.css"
import { ImPhone } from "react-icons/im"
import { AiFillInstagram } from "react-icons/ai"
import iconHowWeAreWorking1 from "../../img/HowWeAreWorking1.png"
import iconHowWeAreWorking2 from "../../img/HowWeAreWorking2.png"
import iconHowWeAreWorking3 from "../../img/HowWeAreWorking3.png"

function Main(props) {
	return (
		<main className={styles.container}>
			<div id='home' className={styles.text_title}>
				<p>Стирка ковров. Быстро и качественно</p>
			</div>
			<div className={styles.text_info}>
				<p>
					Необходимость чистки и стирки ковра, в первую очередь, продиктована
					его способностью накапливать пыль, грязь, частички омертвевшей кожи,
					шерсть животных и волосы. Все это со временем проникает все глубже к
					основанию ковролина и становится отличным местом размножения и
					обитания пылевых клещей и микробов. Продукты жизнедеятельности
					микроорганизмов поднимаются в воздух и появляются в дыхательных путях
					человека. В результате часто развивается аллергия и астма. Поэтому
					звоните нам и мы с удовольствием постираем ваш ковер.
				</p>
			</div>
			<div id='price' className={styles.priceBox}>
				<div className={styles.opacityBox}>
					<p>
						{" "}
						1m<sup>2</sup> - 120 рублей
					</p>
					<p> Вывоз и доставка бесплатно!</p>
					<p>
						{" "}
						<ImPhone size={30} /> <a href='tel:+79287280088'>+7928-728-00-88</a>
					</p>
					<p>
						<AiFillInstagram
							className={styles.opacityBox_iconPhone}
							size={30}
						/>{" "}
						<a href='https://www.instagram.com/aura_stirka/'>aura_stirka</a>
					</p>
				</div>
			</div>
			<div id='howAreWorking' className={styles.workingBox}>
				<p className={styles.textHowWorking}>Как мы работаем?</p>
				<div>
					<img
						className={styles.iconHowWeAreWorking1}
						src={iconHowWeAreWorking1}
						alt='картинка как мы работаем'
					/>
				</div>
				<div>
					<img
						className={styles.iconHowWeAreWorking2}
						src={iconHowWeAreWorking2}
						alt='картинка как мы работаем'
					/>
				</div>
				<div>
					<img
						className={styles.iconHowWeAreWorking3}
						src={iconHowWeAreWorking3}
						alt='картинка как мы работаем'
					/>
				</div>
			</div>
		</main>
	)
}

export default Main
