import Header from "./components/header/header";
import Main from "./components/main/main";


function App() {
  return (
      <div className='container'>
          <Header/>
          <Main/>
      </div>

  );
}

export default App;
